import React, { useState, useEffect } from 'react';

import {
	Container,
	Typography,
	Grid,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core';

import { TemplatesIntro } from './TemplatesIntro';
import { AllIndustryCard } from '../Industry/AllIndustry/AllIndustryCard';

const TileSection = ({ title, tiles }) => {
	return (
		<div>
			<Typography variant='h2' color='primary'>
				{title}
			</Typography>
			<Grid
				container
				direction='row'
				spacing={4}
				justifyContent='center'
				style={{ padding: '4rem 0' }}>
				{tiles.map((tile, index) => (
					<AllIndustryCard industry={tile.node} key={index} templates />
				))}
			</Grid>
		</div>
	);
};

export const AllTemplatesBody = ({ allTemplates, templatesData }) => {
	const { _rawOverviewBody, sectionTitles } = allTemplates;

	const [initialData, setInitialData] = useState([]);
	const [selectedIndustry, setSelectedIndustry] = useState('');

	useEffect(() => {
		setInitialData(templatesData);
	}, []);

	const handleChange = (e) => {
		const { value } = e.target;
		setSelectedIndustry(value);
	};

	return (
		<Container>
			<TemplatesIntro intro={_rawOverviewBody} />
			<FormControl style={{ margin: '8rem 0 4rem 0' }} fullWidth>
				<InputLabel
					id='demo-simple-select-label'
					style={{ marginLeft: '10px', marginTop: '-2px' }}>
					Select Your Industry
				</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					variant='outlined'
					id='demo-simple-select'
					value={selectedIndustry}
					// label='Select Your Industry'
					onChange={handleChange}>
					<MenuItem value={''}>View All</MenuItem>
					{sectionTitles.map((title, index) => (
						<MenuItem
							value={title.toLowerCase().replace(' templates', '')}
							key={index}>
							{title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{sectionTitles.map((title, index) => {
				const initial = initialData.filter(
					(template) =>
						template.node.templateIndustryType.type ===
						title.toLowerCase().replace(' templates', '')
				);

				if (
					selectedIndustry.length &&
					selectedIndustry !== title.toLowerCase().replace(' templates', '')
				) {
					return null;
				}

				return <TileSection title={title} key={index} tiles={initial} />;
			})}
		</Container>
	);
};
